import { useEffect } from 'react';
import { useState, useRef } from 'react';
import { getCategoriesRoutes, loadData } from 'Features/categories/categoryRoutesFetcher';
import { isCategoryRoute } from 'Features/categories/isCategoryRoute';
import useNotifications from '../components/NotificationPopup/useNotifications';
import { useIntl } from 'react-intl';
import { defaultSSR } from './SSRContext';

export const useCategoryRouteValidator = (isValidCategorySSR, pathname, SSRPath) => {
  const intl = useIntl();
  const [isValidCategory, setIsValidCategory] = useState(isValidCategorySSR);
  const lastPathnameRef = useRef(SSRPath);
  const lastPathname = lastPathnameRef?.current;
  const { sendNotification } = useNotifications();

  //todo: affects TTI
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (getCategoriesRoutes() === null) initCategoryRoutesFetcher();
  //   }, 4000);
  // }, []);

  useEffect(() => {
    (async () => {
      if (!pathname && typeof isValidCategory === 'undefined') return;
      if (!pathname && typeof isValidCategory !== 'undefined') {
        setIsValidCategory(defaultSSR.isValidCategorySSR);
        return;
      }
      if (lastPathname !== pathname) {
        lastPathnameRef.current = pathname;
        const categoriesRoutes = getCategoriesRoutes() || (await loadData());
        if (!categoriesRoutes) {
          sendNotification(
            `${intl.formatMessage({ id: 'error.title' })}  ${intl.formatMessage({ id: 'error.description' })}`
          );
          setIsValidCategory(null);
          return;
        }
        const isValid = isCategoryRoute(pathname, categoriesRoutes);
        setIsValidCategory(isValid);
      }
    })();
  }, [pathname, lastPathname]);

  return isValidCategory;
};
